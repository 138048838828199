import { render, staticRenderFns } from "./FarmerDetailTree.vue?vue&type=template&id=55289afc"
import script from "./FarmerDetailTree.vue?vue&type=script&lang=js"
export * from "./FarmerDetailTree.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports